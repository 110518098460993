<template>
  <BCard
    no-body
    class="d-grid p-1 rounded-lg mb-0"
  >
    <BCol class="d-flex justify-content-start">
      <h3 class="text-black font-bold m-0 text-2xl">
        Data Mitra Gudang
      </h3>
    </BCol>
    <div class="flex items-center my-2 justify-end">
      <BInputGroup style="width: 33%">
        <template #prepend>
          <BInputGroupText>
            <feather-icon icon="SearchIcon" />
          </BInputGroupText>
        </template>
        <BFormInput
          v-model="search"
          placeholder="Cari nama atau pemilik mitra gudang"
          @input="searchData"
        />
      </BInputGroup>
      <BButton
        variant="primary"
        class="ml-1"
        size="sm"
        @click="$router.push({ name: 'kompack-tambah-list-data-mitra-gudang' })"
      >
        <div class="flex items-center">
          <img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/add.svg"
            alt="Komerce"
          >
          <div>Tambah Mitra</div>
        </div>
      </BButton>
    </div>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="scroll"
        style="height: calc(100vh - 235px); overflow-y: scroll;"
        class="mt-[5px]"
      >
        <BTable
          responsive
          :items="items"
          :fields="fields"
          show-empty
          empty-text="Tidak ada data yang ditampilkan."
          head-variant="light"
        >
          <template #cell(name)="data">
            <div class="d-flex align-items-center">
              <BAvatar
                :src="data.item.image_logo_url.replace(' ', '%20')"
                alt="dataimg"
                class="mr-1"
              />
              <div class="d-grid align-items-center">
                <h5 class="text-black font-bold mb-0.5">
                  {{ data.item.name }}
                </h5>
                <p class="text-gray-500 my-0">
                  {{ data.item.address }}
                </p>
              </div>
            </div>
          </template>
          <template #cell(phone_number)="data">
            <div class="d-grid align-items-center">
              <p class="text-black mb-0.5">
                {{ data.item.phone_number }}
              </p>
              <p class="text-black my-0">
                {{ data.item.email }}
              </p>
            </div>
          </template>
          <template #cell(status)="data">
            <div class="d-flex justify-content-center">
              <button
                class="rounded-lg py-0.5 px-1"
                :class="dataStatusClass(data.item)"
              >
                <span class="my-0 text-capitalize">{{ dataStatusText(data.item) }}</span>
                <span v-if="data.item.status === 'aktif'"> | <span class="text-gray-600">{{ data.item.total_partner }} partner</span></span>
              </button>
            </div>
          </template>
          <template #cell(rincian)="data">
            <span
              class="font-bold underline text-capitalize"
              style="color: #F95031"
              @click="lihatRincian(data.item)"
            >
              Lihat Profil
            </span>
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <BSpinner class="align-middle" />
              <strong> Loading...</strong>
            </div>
          </template>
        </BTable>
      </div>
    </BOverlay>
  </BCard>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { axiosIns } from '@/libs/axios'
import { columnConfig } from './config'

const warehouseStatus = {
  ACTIVE: 'Active',
  NONACTIVE: 'Non Active',
  NOTVERIFIED: 'Belum Verifikasi',
}

export default {
  data() {
    return {
      loading: true,
      items: [],
      fields: columnConfig,
      search: '',
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    dataStatusClass(value) {
      const statusType = value.status
      if (statusType === warehouseStatus.ACTIVE) {
        return 'bg-green-100 text-green-600 font-medium'
      }
      if (statusType === warehouseStatus.NONACTIVE) {
        return 'bg-red-100 text-red-600 font-medium'
      }
      if (statusType === warehouseStatus.NOTVERIFIED) {
        return 'bg-yellow-100 text-warning font-medium'
      }
      return ''
    },
    dataStatusText(value) {
      const statusType = value.status
      if (statusType === warehouseStatus.ACTIVE) {
        return `Aktif | ${value.total_partner} Partner`
      }
      if (statusType === warehouseStatus.NONACTIVE) {
        return 'Non - Aktif'
      }
      if (statusType === warehouseStatus.NOTVERIFIED) {
        return 'Belum Diverifikasi'
      }
      return 'Belum Diverifikasi'
    },
    lihatRincian(value) {
      this.$router.push({
        name: 'kompack-rincian-mitra-gudang',
        params: {
          id: value.id,
        },
      })
    },
    async getData() {
      this.loading = true
      const url = '/kompack/warehouse'
      const params = {
        search: this.search,
      }
      await axiosIns.get(url, { params })
        .then(({ data }) => {
          this.items = data.data
          this.loading = false
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed',
              text: 'Galat, list mitra gudang',
              variant: 'danger',
              attachment: 'AlertTriangleIcon',
            },
          }, { timeout: 2500 })
          this.loading = false
        })
    },
    searchData: _.debounce(function search(text) {
      this.getData()
    }, 1000),
  },
}
</script>

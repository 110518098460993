/* eslint-disable import/prefer-default-export */
export const columnConfig = [
  {
    key: 'name',
    label: 'Gudang',
    class: 'align-middle text-black',
    tdClass: 'text-black',
    thStyle: {
      textTransform: 'capitalize',
      verticalAlign: 'middle',
      fontSize: '14px',
      minWidth: '300px',
    },
  },
  {
    key: 'owner',
    label: 'Nama Pemilik',
    class: 'align-middle text-black',
    tdClass: 'text-black',
    thStyle: {
      textTransform: 'capitalize',
      verticalAlign: 'middle',
      fontSize: '14px',
      minWidth: '200px',
    },
  },
  {
    key: 'phone_number',
    label: 'Kontak',
    class: 'align-middle text-black',
    tdClass: 'text-black',
    thStyle: {
      textTransform: 'capitalize',
      verticalAlign: 'middle',
      fontSize: '14px',
      minWidth: '100px',
    },
  },
  {
    key: 'status',
    label: 'Status',
    class: 'align-middle text-black text-center',
    tdClass: 'text-black',
    thStyle: {
      textTransform: 'capitalize',
      verticalAlign: 'middle',
      fontSize: '14px',
      minWidth: '230px',
    },
  },
  {
    key: 'rincian',
    label: 'Rincian',
    class: 'align-middle text-black',
    tdClass: 'text-black',
    thStyle: {
      textTransform: 'capitalize',
      verticalAlign: 'middle',
      fontSize: '14px',
      minWidth: '150px',
    },
  },
]
